<template>
  <v-container class="contenedor">
    <v-row>
      <v-col cols="12" class="centrado">
        <h1>¡ Comienza ya con ACO !</h1>
      </v-col>
      <v-col cols="6" class="formulario">
        <v-row>
          <v-col cols="9" class="title centrado">
            Ofrecemos un período de prueba de 30 días, sin tarjetas de crédito
          </v-col>
          <v-col cols="3" class="derecha">
            <v-img src="/img/png/free_trial.png" class="img"></v-img>
          </v-col>
          <v-col cols="12">
            <v-form ref="frmRegistro" class="px-10">
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    solo
                    placeholder="Nombre de la Empresa*"
                    label="Nombre de la Empresa*"
                    :rules="[(v) => !!v || 'Dato requerido.']"
                    v-model="$store.state.enterprises.data.trade_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  Persona de Contacto (Administrador del Sistema)
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-text-field
                    solo
                    placeholder="Nombre*"
                    label="Nombre*"
                    :rules="[(v) => !!v || 'Dato requerido.']"
                    v-model="$store.state.enterprises.data.names"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-text-field
                    solo
                    placeholder="Apellido Paterno*"
                    label="Apellido Paterno*"
                    :rules="[(v) => !!v || 'Dato requerido.']"
                    v-model="$store.state.enterprises.data.surname"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-text-field
                    solo
                    placeholder="Apellido Materno*"
                    label="Apellido Materno*"
                    :rules="[(v) => !!v || 'Dato requerido.']"
                    v-model="$store.state.enterprises.data.second_surname"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="$store.state.enterprises.data.email"
                    solo
                    placeholder="E-mail*"
                    label="E-mail*"
                    :rules="[
                      (v) => !!v || 'Dato requerido',
                      (v) =>
                        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) ||
                        'Debe ser un correo valido',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-5">
                  <v-btn
                    @click="enviarCorreo()"
                    :disabled="!$store.state.enterprises.data.email"
                    color="success"
                    :loading="loading"
                    >Enviar Código</v-btn
                  >
                  <v-snackbar
                    v-model="snackbarEmail"
                    :multi-line="multiLine"
                    :color="colorSnakBar"
                    :timeout="timeout"
                  >
                    <v-icon class="mx-2">mdi-{{ icon }}</v-icon>
                    {{ text }}
                  </v-snackbar>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-autocomplete
                    v-model="selectedCountry"
                    :items="formattedCountries"
                    item-text="name"
                    item-value="id"
                    label="Seleccione un país"
                    solo
                    flat
                    ref="autocomplete"
                    :rules="[(v) => !!v || 'Dato requerido.']"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item v-on:click="selectCountry(item)">
                        <!-- <img
                          :src="item.flagpais"
                          alt="Bandera"
                          width="25"
                          height="25"
                          class="mx-2"
                        /> -->
                        <v-list-item-content>
                          {{ item.name }}
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item }">
                      <!-- <img
                        :src="item.flagpais"
                        alt="Bandera"
                        width="25"
                        height="25"
                        class="mx-2"
                      /> -->
                      {{ item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="8"
                  class="py-0"
                  v-if="!!$store.state.enterprises.data.id_pais"
                >
                  <!-- :prefix="prefix" -->
                  <v-text-field
                    solo
                    placeholder="Teléfono*"
                    label="Teléfono*"
                    :rules="[(v) => !!v || 'Dato requerido.']"
                    v-model="$store.state.enterprises.data.phone"
                  ></v-text-field>
                </v-col>
                <!-- <v-col
                  cols="4"
                  class="py-0"
                  v-if="!!$store.state.enterprises.data.id_pais"
                >
                  <v-btn color="#2D589A" dark> Obtener Código</v-btn>
                </v-col> -->
                <v-col cols="8" class="py-0">
                  <v-text-field
                    v-model="$store.state.enterprises.data.codigo"
                    :rules="codigoRules"
                    solo
                    placeholder="Código de verificación*"
                    label="Código de verificación*"
                    maxlength="4"
                    @keydown="validateNumericInput"
                  ></v-text-field>
                </v-col>
                <!-- <v-col
                  cols="4"
                  class="py-0"
                  v-if="!!$store.state.enterprises.data.id_pais"
                >
                  <v-subheader
                    >Ingresa el código que se ha enviado a tu número de
                    teléfono.</v-subheader
                  >
                </v-col> -->
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="12" class="centrado">
                <p>Comienza tu trial de 30 días</p>
              </v-col>
              <v-col cols="12" class="pt-0 centrado">
                <v-btn
                  @click="crearNuevaEmpresa()"
                  color="red"
                  dark
                  class="mx-1"
                  :loading="loadingRegistro"
                >
                  Empezar Trial de 30 días</v-btn
                >
                <!-- <v-btn
                  color="#263238"
                  dark
                  class="mx-1"
                  @click="$router.push({ name: 'Login' })"
                >
                  Regresar Login</v-btn
                >
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="12" class="centrado">
            <h1>
              <span class="red--text">A</span>gente de
              <span class="red--text">C</span>arga
              <span class="red--text">O</span>nline
            </h1>
          </v-col>
          <v-col cols="12" class="centrado">
            <h2>Toda tu empresa interconectada en un solo sistema</h2>
          </v-col>
          <v-col cols="12" class="ma-auto centrarLogo">
            <v-img class="logo" src="/img/logo/logo.jfif" />
          </v-col>
          <v-col cols="12" class="derecha">
            <BotonChateaConNostros />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" scrollable persistent width="400px">
      <v-card>
        <v-card-text class="mt-5">
          <h2>Tu Usuario Administrador del sistema ha sido creado</h2>
          <h3 class="mt-10">Revisa tu email</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="continuar()">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {
    BotonChateaConNostros: () => import("../comun/botonChateaConNosotros.vue"),
  },
  data() {
    return {
      dialog: false,
      validatedCodigo: "",
      icon: "",
      colorSnakBar: "",
      text: "",
      snackbarEmail: false,
      timeout: 2000,
      multiLine: true,
      loading: false,
      itemPais: [],
      selectedCountry: null,
      prefix: "",
      id_pais: "",
      loadingRegistro: false,
      mask: "######", // Define la máscara para aceptar solo dígitos
      codigoRules: [
        (v) => !!v || "Dato requerido.",
        (v) => /^\d+$/.test(v) || "Ingrese solo números.",
      ],
    };
  },
  async mounted() {
    await this._getPais();

    console.log("componentne montado");
    this.$refs.frmRegistro.reset();
  },
  methods: {
    ...mapActions([
      "_getPais",
      "RegistroNuevaEmpresa",
      "enviarCorreoValidacion",
      "validarCodigoVerificacion",
    ]),
    // formattedCountries() {
    //   return this.$store.state.itemsPais.map((country) => ({
    //     ...country,
    //     formattedText: `${country.name}`,
    //   }));
    // },
    async crearNuevaEmpresa() {
      this.validatedCodigo = "";
      if (this.$refs.frmRegistro.validate()) {
        let res = await this.validarCodigoVerificacion(true);
        if (res.estadoflag) {
          this.loadingRegistro = true;
          setTimeout(() => {
            let res = this.RegistroNuevaEmpresa();
            this.loadingRegistro = false;

            this.dialog = true;
          }, 30 * 1000);
        } else {
          this.validatedCodigo = res.mensaje;
          this.snackbarEmail = true;
          this.colorSnakBar = "orange darken-2";
          this.text = res.mensaje;
          this.icon = "alert";
        }
      }
    },
    validateNumericInput(event) {
      const keyCode = event.keyCode;
      // Permitir solo números (48-57 son los códigos ASCII para 0-9)
      if (
        !(
          (keyCode >= 48 && keyCode <= 57) ||
          (keyCode >= 96 && keyCode <= 105) ||
          [8, 37, 38, 39, 40].includes(keyCode)
        )
      ) {
        event.preventDefault();
      }
    },
    selectCountry(item) {
      this.selectedCountry = item;
      this.prefix = `+${item.phone_code}`;
      this.$store.state.enterprises.data.id_pais = item.id;
      this.closeMenu();
    },
    continuar() {
      let dominio = this.obtenerDominio();
      if (dominio) {
        window.open(dominio, "_blank");
        // window.location.href = dominio;
        this.$router.push({ name: "Login" });
      } else {
        alert("No se puede identificar el proveedor de correo electrónico.");
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 1000);
      }
    },
    obtenerDominio() {
      const domain = this.$store.state.enterprises.data.email.split("@")[1];
      switch (domain) {
        case "gmail.com":
          return "https://mail.google.com/";
        case "yahoo.com":
          return "https://mail.yahoo.com/";
        case "outlook.com":
        case "hotmail.com":
          return "https://outlook.live.com/mail/";
        default:
          return null;
      }
    },
    closeMenu() {
      this.$refs.autocomplete.blur();
    },
    async enviarCorreo() {
      let val = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
        this.$store.state.enterprises.data.email
      );

      if (val) {
        this.loading = true;
        this.loadingRegistro = true;
        this.enviarCorreoValidacion().catch((err) => {
          console.log(err);
        });
        setTimeout(() => {
          this.loading = false;
          this.loadingRegistro = false;
          this.colorSnakBar = "success";
          this.icon = "check-circle";
          this.text = "Ingrese el código que se le ha enviado a su correo.";
          this.snackbarEmail = true;
        }, 1000);
      } else {
        this.snackbarEmail = !val;
        this.colorSnakBar = "orange darken-2";
        this.text = "Ingrese un correo valido.";
        this.icon = "alert";
      }
    },
    async validarCodigo() {
      // this.validatedCodigo = "";
      let res = await this.validarCodigoVerificacion(false);
      if (!res.estadoflag) {
        this.snackbarEmail = true;
        // this.validatedCodigo = res.mensaje;
        this.colorSnakBar = "orange darken-2";
        this.text = res.mensaje;
        this.icon = "alert";
      }
    },
  },
  computed: {
    formattedCountries() {
      return this.$store.state.itemsPais.map((country) => ({
        ...country,
        formattedText: `${country.name}`,
      }));
    },
  },
};
</script>

<style scoped>
.derecha {
  align-content: right !important;
  text-align: right !important;
}
.izquierda {
  align-content: left !important;
  text-align: left !important;
}
.centrado {
  align-content: center !important;
  text-align: center !important;
}
.title {
  text-align: justify;
  font-size: 32px !important;
}
.formulario {
  border-radius: 20px;
  background: #eeeeee !important;
}
.img {
  float: right;
  max-width: 100%;
}
.img:hover {
  animation: fire 0.8s infinite;
  transform-origin: center;
}
.centrarLogo {
  margin: 10% 25% !important;
}
.logo {
  max-width: 50%;
  background: #fff;
  border-radius: 50%;
}
.contenedor {
  max-width: 60% !important;
  /* border: solid 1px #000; */
  margin-top: 2% !important;
}

@keyframes fire {
  from {
    transform: scale(1.5);
  }
  to {
    transform: scale(1.5);
  }
}
</style>
